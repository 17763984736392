<script lang="ts" setup>
import { ref } from 'vue'
import { $fetch } from '@@home/plugins/fetch';

const today = new Date();
const isSubscribe = ref(false);
const subscribeParams = ref({ name: '',  email: '' });
const isCorrect = ref({ name: true, email: true });

const postSubscribe = async () => {
  const { isSuccess } =  await $fetch('/email/v1/subscribe', {
    data: { ...subscribeParams.value, source: 1 }
  })
  if (isSuccess) {
    isSubscribe.value = true;
  } else {
    isSubscribe.value = false;
  }
}

const handleSubscribe = () => {
  const isEmailValidResult = isEmailValid(subscribeParams.value.email);
  const isNameValidResult = isNameValid(subscribeParams.value.name);

  isCorrect.value.email = isEmailValidResult;
  isCorrect.value.name = isNameValidResult;

  if (isEmailValidResult && isNameValidResult) {
    postSubscribe()
  }
};

// 文本格式校验
function isEmailValid(email: string) {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
}
function isNameValid(name: string) {
  const pattern = !!name;
  return  pattern;
}
</script>

<template>
  <footer class="page-footer">
    <div class="page-footer-container mx-auto max-w-1360px py-64px <2xl:max-w-1200px">
      <div class="page-footer-subscribe">
        <h2 class="m-0 text-26px color-hex-FFFFFF font-500 leading-36px">{{ $t('footer.emailTitle') }}</h2>
        <p class="m-0 mt-16px text-14px color-hex-FFFFFF font-400 leading-20px">{{ $t('footer.emailInfo') }}</p>
        <div>
          <div v-if="isSubscribe" class="mt-24px h-56px rounded-4px bg-#1180FF text-center text-20px color-hex-FFFFFF font-500 leading-56px <2xl:text-18px">{{ $t('footer.subscribeSuccess') }}</div>
          <div class="mt-24px h-80px flex justify-between" v-else>
            <div>
              <input type="text" name="fullname" autocomplete="off" :placeholder="$t('footer.placegolderName')" v-model.trim="subscribeParams.name" :class="isCorrect.name ? 'border-#FFFFFF/50' : ' border-#FF4D4F'" class="box-border h-56px w-478px border-1px rounded-4px pl-12px text-20px color-hex-FFFFFF font-400 leading-24px outline-none <2xl:w-398px <2xl:text-18px">
              <div v-if="!isCorrect.name" class="mt-4px text-14px color-hex-FF4D4F font-400 leading-20px">{{ $t('footer.nameError') }}</div>
            </div>
            <div>
              <input type="text" name="email" autocomplete="off" :placeholder="$t('footer.placegolderEmail')" v-model="subscribeParams.email" :class="isCorrect.email ? 'border-#FFFFFF/50' : ' border-#FF4D4F'" class="box-border h-56px w-478px border-1px rounded-4px pl-12px text-20px color-hex-FFFFFF font-400 leading-24px outline-none <2xl:w-398px <2xl:text-18px">
              <div v-if="!isCorrect.email" class="mt-4px text-14px color-hex-FF4D4F font-400 leading-20px">{{ $t('footer.emailError') }}</div>
            </div>
            <button @click="handleSubscribe()" class="h-56px w-200px cursor-pointer border-0 rounded-4px bg-#1180FF text-20px color-hex-FFFFFF font-500 leading-24px <2xl:text-18px">{{ $t('footer.subscribe') }}</button>
          </div>
        </div>
        <div class="mb-64px mt-32px h-1px w-full bg-hex-FFFFFF/50"></div>
      </div>

      <div class="footer-info-box flex">
        <div class="footer-info-box__hd mr-80px flex flex-col justify-between <2xl:mr-49px">
          <div class="logo">
            <img class="lazyload h-full w-full object-contain" data-src="@ssr/home/assets/common/logo-with-title-white.svg" alt="LOGO">
          </div>
        </div>
        <div class="footer-info-box__bd flex-grow table text-16px text-white font-normal">
          <div class="table-row-group">
            <div class="table-row">
              <div class="w-19% table-cell pb-22px pr-20px <2xl:w-16%">
                <RouterLink to="/privacy-policy" target="_blank">{{ $t('footer.content1') }}</RouterLink>
              </div>
              <div class="w-35% table-cell pb-22px pr-10px <2xl:w-33%">
                {{ $t('footer.content5') }}<a href="mailto:media@openatom.org">media@openatom.org</a>
              </div>
              <div class="table-cell pb-22px">
                {{ $t('footer.address') }}
              </div>
            </div>
            <div class="table-row">
              <div class="table-cell pb-22px pr-20px">
                <RouterLink to="/terms" target="_blank">{{ $t('footer.content2') }}</RouterLink>
              </div>
              <div class="table-cell pb-22px pr-10px">
                {{ $t('footer.content6') }}<a href="mailto:report@openatom.org">report@openatom.org</a>
              </div>
              <div class="table-cell pb-22px">
                {{ $t('footer.content9') }}<a href="mailto:sponsorship@openatom.org">sponsorship@openatom.org</a>
              </div>
            </div>
            <div class="table-row">
              <div class="table-cell pb-22px pr-20px">
                <RouterLink to="/brand-guide" target="_blank">{{ $t('footer.content3') }}</RouterLink>
              </div>
              <div class="table-cell">
                {{ $t('footer.content11') }}<a href="mailto:legal@openatom.org">legal@openatom.org</a>
              </div>
              <div class="table-cell pb-22px">
                {{ $t('footer.content10') }}<a href="mailto:sponsorship@openatom.org">sponsorship@openatom.org</a>
              </div>
            </div>
            <div class="table-row">
              <div class="table-cell pr-20px">
                <a href="https://atomgit.com/OpenAtomFoundation/brand-identity" target="_blank">{{ $t('footer.content4') }}</a>
              </div>
              <div class="table-cell pr-10px">
                {{ $t('footer.content8') }}<a href="mailto:hr@openatom.org">hr@openatom.org</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="logo mt-68px">
        <ul class="socially-pic m-0 flex list-none p-0">
          <li class="i-home-icons:weibo h-40px w-40px hover:i-home-icons:weibo-hover">
            <a class="block h-full w-full" href="https://weibo.com/u/7839689482" target="_blank" aria-label="weibo"></a>
          </li>
          <li class="use-qrcode tiktok i-home-icons:tiktok relative hover:i-home-icons:tiktok-hover">
            <img class="lazyload absolute hidden" data-src="https://res.oafimg.cn/-/5f42f886a1f2c3d7/tiktok.jpg" alt="tiktok">
          </li>
          <li class="use-qrcode wechat-video relative">
            <img class="lazyload absolute hidden" data-src="https://res.oafimg.cn/-/49b3044892e59392/wechat-video.jpg" alt="wechat video">
          </li>
          <li class="use-qrcode i-home-icons:wechat relative hover:i-home-icons:wechat-hover">
            <img class="lazyload absolute hidden" data-src="https://res.oafimg.cn/-/1ed49cd64a791286/wechat.png" alt="wechat">
          </li>
          <li class="i-home-icons:bilibili hover:i-home-icons:bilibili-hover">
            <a class="block h-full w-full" href="https://space.bilibili.com/672606361" target="_blank" aria-label="BiliBili"></a>
          </li>
        </ul>
      </div>

      <div class="copyright flex justify-between font-normal">
        <p class="m-0">
          Copyright {{ today.getFullYear() }} OPENATOM FOUNDATION. All rights reserved.
        </p>
        <p class="m-0">
          {{ $t('footer.content12') }}
          <a href="https://beian.miit.gov.cn/" target="_blank">{{ $t('footer.beian') }}</a>
          <img data-src="https://res.oafimg.cn/-/e2468638b6adb4de/police.png" width="16" height="16" class="lazyload mx-5px" alt="police">
          <span>{{ $t('footer.content13') }}</span>
        </p>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.page-footer {
  width: 100%;
  background-color: #001935;

  .page-footer-subscribe {
    input {
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.2) 100%);
    }

    input:focus {
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.4) 100%);
      border: 1px solid #fff;
    }

    input::placeholder {
      color: rgba(255, 255, 255, 0.5);
      font-weight: 400;
    }
  }

  .page-footer-container {
    .socially-pic {
      li {
        width: 40px;
        height: 40px;

        + li {
          margin-left: 16px;
        }
      }

      .use-qrcode {
        &:hover {
          img {
            display: block;
          }
        }

        img {
          bottom: 50px;
          left: 50%;
          width: 130px;
          height: 130px;
          padding: 10px;
          object-fit: cover;
          background-color: #fff;
          border-radius: 4px;
          transform: translateX(-50%);
        }
      }

      .wechat-video {
        background: url(@@home/assets/common/wechat-video.png) no-repeat;
        background-size: contain;

        &:hover {
          background: url(@@home/assets/common/wechat-video-hover.png) no-repeat;
          background-size: contain;
        }
      }
    }

    .footer-info-box {
      .footer-info-box__hd {
        .logo {
          width: 188px;
          // height: 40px;
        }
      }

      .footer-info-box__bd {
        a {
          color: #fff;

          @apply no-underline hover:underline;
        }
      }
    }

    hr {
      height: 1px;
      margin-top: 72px;
      background-color: rgba(255, 255, 255, 0.5);
    }

    .copyright {
      margin-top: 39px;
      color: #fff;
      font-size: 14px;

      p {
        line-height: 1.6;
      }

      img {
        vertical-align: -3px;
      }

      a {
        color: #fff;

        @apply no-underline hover:underline;
      }
    }
  }
}
</style>
