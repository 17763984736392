import { ref } from 'vue';
import { defineStore } from 'pinia';
import { getInstance } from '@@home/plugins/i18n/index';

// 全局 APP 相关信息放在这个 store 中

export const useAppStore = defineStore('app', () => {
  const language = ref<string | undefined>('');

  function getWrappedTitle(title: string) {
    const locale = getInstance();

    return title ? `${title} - ${locale.global.t('websiteTitle.default')}` : locale.global.t('websiteTitle.default');
  }

  function setTitle(title: string) {
    document.title = getWrappedTitle(title);
  }

  return { language, setTitle, getWrappedTitle };
});
