import { createApp } from './main';

const { app, router } = createApp();

router.isReady().then(() => {
  app.mount('#app');

  // 为防止 SSR 客户端激活失败，延迟加载 lazysizes
  import('lazysizes');
});
