<script lang="ts" setup>
import { useRoute, RouterView } from 'vue-router';
import { computed, useId } from 'vue';
import { provideUseId } from '@headlessui/vue';
import LayoutNormal from '@@home/layouts/normal.vue';

provideUseId(() => useId() as string);

const route = useRoute();

const layout = computed(() => {
  if (route.meta.layout) {
    return route.meta.layout;
  }
  else {
    return LayoutNormal;
  }
});
</script>

<template>
  <img src="@@home/assets/common/logo-300x300.png" alt="开放原子开源基金会" class="block h-0 w-0">
  <Component :is="layout">
    <RouterView />
  </Component>
</template>

<style lang="scss" scoped>
</style>
