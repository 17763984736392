// 公共常量

// Cookie 当前语言 Key
export const COOKIE_KEY_LANGUAGE = 'openatom_official_website_language';

// 可用语言
export const AVAILABLE_LANGUAGES = ['zh-CN', 'en-US'];

// 默认语言
export const DEFAULT_LANGUAGE = AVAILABLE_LANGUAGES[0];

// 捐赠人主分类 ID: 基金会
export const CONTRIBUTOR_CATEGORY_MAIN = 'zaO6MK';

// 捐赠人子分类 ID: 白金
export const CONTRIBUTOR_CATEGORY_SUB_PLATINUM = 'IGNAWG';

// 捐赠人子分类 ID: 黄金
export const CONTRIBUTOR_CATEGORY_SUB_GOLD = 'ZsKjAM';

// 捐赠人子分类 ID: 白银
export const CONTRIBUTOR_CATEGORY_SUB_SILVER = 'UKe1LT';

// 捐赠人子分类 ID: 贡献
export const CONTRIBUTOR_CATEGORY_SUB_SPONSOR = 'czqjtH';
