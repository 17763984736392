<script lang="ts" setup>
import { onMounted, ref, onUnmounted } from 'vue';
import { useThrottleFn } from '@vueuse/core'

const MAX_SCROLL = 500
const opacity = ref(0)
const isVisible = ref(true)

const toTop = () => {
  const time = setInterval(() => {
    if (document.documentElement.scrollTop <= 0) {
      clearInterval(time)
    }
    document.documentElement.scrollTop -= 100
  }, 1)
}

const opactiyChange = () => {
  opacity.value = (document.documentElement.scrollTop / MAX_SCROLL >= 1) ? 1 : document.documentElement.scrollTop / MAX_SCROLL
  isVisible.value = document.documentElement.scrollTop > 0
}

const throttleFn = useThrottleFn(opactiyChange, 10)

onMounted(() => {
  document.addEventListener('scroll', throttleFn)
})

onUnmounted(() => {
  document.removeEventListener('scroll', throttleFn)
})
</script>

<template>
  <div v-if="isVisible" class="top-button fixed bottom-120px right-2.5% z-999 h-48px w-48px cursor-pointer rounded-48px text-center" @click="toTop">
    <img src="@@home/assets/components/button.svg" class="mt-8px h-32px w-32px" alt="Goto Top">
  </div>
</template>

<style lang="scss" scoped>
.top-button {
  background: rgba(45, 45, 45, 0.08);
  opacity: v-bind("opacity");
  transition: all .5s linear;

  &:hover {
    background: rgba(45, 45, 45, 0.16);
  }
}
</style>
