import type { AxiosResponse, AxiosError } from 'axios';

import axios from 'axios';
import { getInstance } from '@@home/plugins/i18n/index';

export declare interface FetchOptions {
  method?: string,
  upload?: boolean,
  data?: any,
  json?: boolean,
  cache?: boolean,
  headers?: any,
  onUploadProgress?: (progress: any) => void,
  onUploadPercent?: (percent: number, progressEvent: any) => void,
};

export declare interface FetchResult<ResT> {
  isSuccess: boolean,
  code: number | string,
  msg?: string,
  data?: ResT,
  $response?: AxiosResponse | AxiosError | Error,
};

const DEFAULT_ERROR_CODE = 99999;

// 创建axios实例
const instance = axios.create({
  baseURL: import.meta.env.SSR ? import.meta.env.VITE_SERVER_BASE_URL : import.meta.env.VITE_BASE_URL,
  timeout: 30000
});

// request拦截器
instance.interceptors.request.use(config => {
  if (config.method === 'get') {
    config.params = config.data;
  }

  return config;
}, error => {
  // Do something with request error
  console.error(error) // for debug
  Promise.reject(error)
});

export async function $fetch<ResT = any>(url: string, inputOptions: FetchOptions = {}): Promise<FetchResult<ResT>> {
  const options = { ...inputOptions };
  const locale = getInstance();

  if (!options.method) {
    options.method = 'post';
  }

  if (!options.data) {
    options.data = {};
  }

  options.data.lc = locale.global.locale.value.split('-')[0];

  if (!options.headers) {
    options.headers = {};
  }

  if (options.upload === true) {
    const fd = new FormData();

    for (const key in options.data) {
      if (Object.prototype.hasOwnProperty.call(options.data, key)) {
        const value = options.data[key];

        if (value === undefined) {
          continue;
        }

        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            if (typeof item === 'object') {
              for (const subKey in item) {
                if (Object.prototype.hasOwnProperty.call(item, subKey)) {
                  fd.append(`${key}[${index}][${subKey}]`, item[subKey]);
                }
              }
            }
            else {
              fd.append(`${key}[]`, item);
            }
          });
        }
        else {
          fd.append(key, value);
        }
      }
    }

    options.data = fd;
  }
  else if (options.method === 'post') {
    if (options.json !== false) {
      options.headers['Content-Type'] = 'application/json; charset=utf-8';
    }
    else {
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }
  }

  if (options.method.toLowerCase() === 'get' && options.cache !== true) {
    options.data._ = String(+new Date()) + String(Math.floor(Math.random() * 9999));
  }

  if (!options.onUploadProgress && options.onUploadPercent) {
    options.onUploadProgress = (progressEvent) => {
      if (progressEvent.lengthComputable) {
        options.onUploadPercent?.(Math.floor((progressEvent.loaded / progressEvent.total) * 100), progressEvent);
      }
    }
  }

  let result: FetchResult<ResT>;

  try {
    const response = await instance(url, options);

    result = {
      isSuccess: response?.data?.success ?? true,
      code: response?.data?.code ?? 0,
      data: response?.data?.data,
      msg: response?.data?.message,
      $response: response,
    }
  }
  catch (error: any) {
    if (axios.isAxiosError(error)) {
      const response = error.response;

      result = {
        isSuccess: response?.data?.success ?? false,
        code: response?.data?.error?.code ?? DEFAULT_ERROR_CODE,
        data: response?.data?.error?.details,
        msg: response?.data?.error?.message || response?.data?.message || error.message,
        $response: response,
      }
    }
    else {
      result = {
        isSuccess: false,
        code: DEFAULT_ERROR_CODE,
        msg: error.message,
        $response: error,
      }
    }
  }

  return result;
};
